<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fff;
  width: 100vw;
  /*margin-top: 3vw;*/
}
.root_box{
  width: 94vw;
  margin-left: 3vw;
  background: #fff;
}
:root:root {
  --van-nav-bar-title-font-size	: 18px;
  --van-nav-bar-title-text-color:#333333;
}
.div_row{
  display: flex;
  flex-direction: row;
}
.div_col{
  display: flex;
  flex-direction: column;
}

.div_row_center{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.div_col_center{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.div_row_between{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.div_row_right{
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
}
</style>
