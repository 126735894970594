import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const routes = [
    {
        path: '/',
        redirect: '/login'
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/pages/Login.vue')
    },
    {
        path: '/process',
        name: 'Process',
        component: () => import('@/pages/Process.vue')
    },
    {
        path: '/processSuccess',
        name: 'processSuccess',
        component: () => import('@/pages/ProcessSuccess.vue')
    },
]

export default new Router({
    routes
})


